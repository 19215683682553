import {TPTaxReportData} from "src/models/reports/TPTaxReport";
import React from "react";
import ServiceCollection from "src/services/ServiceCollection";
import {TP_TAX_REPORT_FORMATTING_EXCLUSION_SET} from "src/utils/constants";
import {useCollection} from "@amzn/awsui-collection-hooks";
import Table from "@amzn/awsui-components-react/polaris/table";


export default function TPTaxReportTableGrid(props: { data: TPTaxReportData[], services: ServiceCollection }) {

    const {data = [], services} = props;

    function formatAttributeValue(attributeName: string, attributeValue: any): string {
        if (!attributeValue) {
            return attributeValue;
        } else if (TP_TAX_REPORT_FORMATTING_EXCLUSION_SET.has(attributeName)) {
            return attributeValue
        }
        return isNaN(attributeValue) ? attributeValue :
            services.formattingService.formatString(attributeValue, false);
    }

    const columnDefinitions = [
        {
            id: 'attributeName',
            header: 'Attribute Name',
            cell: (item: any) => item.attributeName,
        },
        {
            id: 'attributeValue',
            header: 'Attribute Value',
            cell: (item: any) => formatAttributeValue(item?.attributeName, item?.attributeValue)
        }
    ];

    const { items: rootItems, collectionProps } = useCollection(data, {
        expandableRows: {
            getId: (item) => item.attributeName,
            getParentId: (item) => item.attributeParentName?? null,
        }
    });

    return (
        <Table
            items={rootItems}
            columnDefinitions={columnDefinitions}
            {...collectionProps}
        />
    );
}