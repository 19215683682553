//Constants
import KeyValuePair from 'src/models/common/KeyValuePair'
import {StatusIndicatorProps} from "@amzn/awsui-components-react";

const CONSTANTS = Object.freeze({
    DEFAULT_TIMEZONE: 'America/Los_Angeles',
    GENERIC_ERROR_MESSAGE: 'There was a problem with your request. Please try again later or contact Technical support.',
    PRODUCTION: 'production',
    PAGE_NAV: {
        DASHBOARD: {
            URL: '/',
            TITLE: 'Overview',
            DESCRIPTION: 'View and manage your TP agreements and PT calcs.'
        },
        AGREEMENTS: {
            URL: '/agreements',
            TITLE: 'TP Worklists',
            DESCRIPTION: 'View and manage your TP agreements.',
            AGREEMENTS_TAB_NAME: 'Apttus Agreements',
            CALCULATIONS_TAB_NAME: 'Apttus Calculations',
            ICRS_TAB_NAME: 'IC Transactions without Agreements (ICRS)'
        },
        AGREEMENT_DETAILS: {
            URL: '/agreements/details',
            TITLE: 'TP Agreement',
            DESCRIPTION: 'View and manage your TP agreement.'
        },
        REPORTS_HISTORY: {
            URL: '/reports/history',
            TITLE: 'ATP Reports History',
            DESCRIPTION: 'Download your ATP Reports',
        },
         REPORT_DASHBOARD: {
            URL: '/reports',
            TITLE: 'ATP Reporting Dashboard',
            DESCRIPTION: 'View reporting service metrics and easily access core ATP reports',
         },
        TAX_AUDIT_RECORD: {
            URL: '/reports/taxAuditRecord',
            TITLE: 'Tax Audit Record Report',
            DESCRIPTION: 'View Tax Audit Records',
        },
        TAX_AUDIT_RECORD_DETAIL: {
            URL: '/reports/taxAuditRecord/details',
            TITLE: 'Tax Audit Record',
            DESCRIPTION: 'View and Download Tax Audit Record Details',
        },
        TP_TAX_VARIANCE_RECORD: {
            URL: '/reports/tpTaxVarianceRecords',
            TITLE: 'TP Tax Variance Report',
            DESCRIPTION: 'View TP Tax Variance Records',
            HEADER_SIDEBAR_KEY: 'tpTaxVarianceRecordsPageSideBar'
        },
        TP_TAX_LEF_REPORT: {
            URL: '/reports/lef',
            TITLE: 'TP Tax Lef Report',
            DESCRIPTION: 'View Legal Entity Financial Report',
        },
        TP_TAX_CWB_REPORT: {
            URL: '/reports/cwb',
            TITLE: 'TP Tax CWB Report',
            DESCRIPTION: 'View Workbook Report',
        },
        CALCULATIONS: {
            URL: '/calculations',
            TITLE: 'TP Calculation Manager',
            DESCRIPTION: 'Create and automate Transfer Pricing calculations.'
        },
        SEARCH_CALCULATION: {
            URL: '/calculations/search',
            TITLE: 'Search Calculation',
            DESCRIPTION: 'View an existing calculation\'s details.',
        },
        CALCULATION_BUILDER: {
            URL: '/calculations/builder',
            TITLE: '',
            DESCRIPTION: '',
            NEW_CALC_TITLE: 'New Calculation',
            NEW_CALC_DESCRIPTION: 'Provide and transform required source data to create and automate Transfer Pricing calculations.',
            NEW_CALC_BREADCRUMB: 'New Calculation - (cli)',
            EXISTING_CALC_TITLE: 'Existing Calculation',
            EXISTING_CALC_DESCRIPTION: 'Calculation details.',
            EXISTING_CALC_BREADCRUMB: 'Existing Calculation - (cli)'
        },
        ICRS_CALCULATION_BUILDER: {
            URL: '/icrs-calculations/builder',
            TITLE: '',
            DESCRIPTION: '',
            NEW_CALC_TITLE: 'New Calculation',
            NEW_CALC_DESCRIPTION: 'Provide and transform required source data to create and automate Transfer Pricing calculations.',
            NEW_CALC_BREADCRUMB: 'New Calculation - (cli)',
            EXISTING_CALC_TITLE: 'Existing Calculation',
            EXISTING_CALC_DESCRIPTION: 'Calculation details.',
            EXISTING_CALC_BREADCRUMB: 'Existing Calculation - (cli)'
        },
        READONLY_CALCULATION: {
            URL: '/calculations/readOnly',
            TITLE: 'Existing Calculation',
            DESCRIPTION: 'View an existing calculation\'s details.',
        },
        LINK_TO_TEMPLATE: {
            URL: '/calculations/link',
            TITLE: 'Link to Template',
            DESCRIPTION: 'Link multiple calculations to a template.',
        },
        CLONE_CALCULATION: {
            URL: '/calculations/clone',
            TITLE: 'Clone calculation',
            DESCRIPTION: 'Clone multiple calculations from a template.',
        },
        CUSTOM_TABLES: {
            URL: '/calculations/customDataTables',
            TITLE: 'Custom Data Tables',
            DESCRIPTION: 'View custom data tables.',
        },
        CUSTOM_DATA_TABLE_DETAILS: {
            URL: '/calculations/customDataTables/details',
            TITLE: 'Custom Data Table',
            DESCRIPTION: 'View and manage a Custom Data Table.'
        },
        CUSTOM_COA: {
            URL: '/calculations/customCoa',
            TITLE: 'Custom COA Definitions',
            DESCRIPTION: 'View custom COA definitions.'
        },
        CUSTOM_COA_DETAILS: {
            URL: '/calculations/customCoa/details',
            TITLE: 'Custom COA Definition',
            DESCRIPTION: 'View and manage a Custom COA Definition.'
        },
        TP_ALLOCATION: {
            URL: '/calculations/tpAllocation',
            TITLE: 'TP Allocation',
            DESCRIPTION: 'View TP Allocation worksheets.'
        },
        TP_ALLOCATION_STANDALONE: {
            URL: '/calculations/tpAllocation/standalone',
            TITLE: 'TP Allocation Worksheet',
            DESCRIPTION: 'View and manage a TP Allocation worksheet.'
        },
        TP_ALLOCATION_LINKED: {
            URL: '/calculations/tpAllocation/linked',
            TITLE: 'TP Allocation Worksheet',
            DESCRIPTION: 'View and manage a TP Allocation worksheet.'
        },
        TP_ALLOCATION_TEMPLATE: {
            URL: '/calculations/tpAllocation/template',
            TITLE: 'TP Allocation Worksheet Template',
            DESCRIPTION: 'View and manage a TP Allocation worksheet template.'
        },
        PRE_VALIDATION_REPORTS: {
            URL: '/reports/prevalidation',
            TITLE: 'Month End Close Report',
            DESCRIPTION: 'Pre validation reports',
        },
        MONTH_END_CLOSE: {
            URL: '/mec',
            TITLE: 'Month End Close',
            DESCRIPTION: 'View and manage Month End Close execution'
        },
        TP_TAX_REPORT: {
            URL: '/reports/tpTaxReport',
            TITLE: 'TP Tax Report',
            DESCRIPTION: 'Provides a comprehensive Transfer Pricing P&L view and profit level indicators for individual CLIs'
        },
        BULK_ACTIONS: {
            URL: '/calculations/bulkActions',
            TITLE: 'Bulk Actions',
            DESCRIPTION: 'UAT-only convenience feature to bulk submit and bulk approve calculations'
        },

    },
    WORKLIST_FILTER_FIELDS: {
        AGREEMENT_STATUS_CATEGORY: new KeyValuePair("agreementStatusCategory", "Agreement status category"),
        AGREEMENT_STATUS: new KeyValuePair("agreementStatus", "Agreement status"),
        CALCULATION_STATUS: new KeyValuePair("calculationStatus", "Calculation status"),
        TAX_OWNER: new KeyValuePair("taxOwner", "TP Tax owner"),
        ACCOUNTING_OWNER: new KeyValuePair("accountingOwner", "TP Accounting owner"),
        EFFECTIVE_DATE: new KeyValuePair("effectiveDate", "Effective date"),
        EFFECTIVE_DATE_FROM: new KeyValuePair("effectiveDateFrom", "Effective date from"),
        EFFECTIVE_DATE_TO: new KeyValuePair("effectiveDateTo", "Effective date to"),
        TASK_TYPE: new KeyValuePair("taskType", "Task type"),
        CALC_ASSIGNEE: new KeyValuePair("assignee", "Calc Assignee"),
        CALC_BUILDER: new KeyValuePair("calculation_builder", "Calc Builder"),
        WORKBOOK_NAME: new KeyValuePair("workbookName", "Workbook Name"),
    },
    CALCULATIONS_SEARCH_FIELDS: {
        AGREEMENT_NUMBER: new KeyValuePair("agreementNumber", "Agreement Number"),
        AGREEMENT_LINE: new KeyValuePair("agreementLineNumber", "Agreement Line"),
        CALCULATION_LINE: new KeyValuePair("calculationNumber", "Calculation Line"),
        CALCULATION_NAME: new KeyValuePair("calculationName", "Calculation name"),
        AGREEMENT_STATUS: new KeyValuePair("agreementStatus", "Agreement status"),
        CALCULATION_STATUS: new KeyValuePair("calculationStatus", "TP Calculation status"),
        TAX_OWNER: new KeyValuePair("taxOwner", "Tax owner"),
        ACCOUNTING_OWNER: new KeyValuePair("accountingOwner", "Accounting owner"),
        PROVIDER_CODE: new KeyValuePair("providerCode", "First party"),
        RECIPIENT_CODE: new KeyValuePair("recipientCode", "Second party"),
        LAST_UPDATED: new KeyValuePair("lastUpdatedDate", "Last Updated"),
    },
    ICRS_SEARCH_FIELDS: {
        TRANSACTION_LINE: new KeyValuePair("registrationNumber", "Registration Number"),
        TP_CALCULATION: new KeyValuePair("tpCalculation", "TP Calculation"),
        EFFECTIVE_FROM_DATE: new KeyValuePair("effectiveDateFrom", "Effective From Date"),
        WORKBOOK_NAME: new KeyValuePair("workbookName", "Workbook Name"),
        BEAT: new KeyValuePair("beat", "Beat"),
        DESCRIPTION: new KeyValuePair("description", "Description"),
        COMMENTS: new KeyValuePair("comments", "Comments"),
        PROVIDER_COMPANY_CODE: new KeyValuePair("providerCompanyCode", "Provider Company Code"),
        PROVIDER_ENTITY_NAME: new KeyValuePair("providerEntityName", "Provider Entity Name"),
        PROVIDER_JURISDICTION: new KeyValuePair("providerJurisdiction", "Provider Jurisdiction"),
        RECIPIENT_COMPANY_CODE: new KeyValuePair("recipientCompanyCode", "Recipient Company Code"),
        RECIPIENT_ENTITY_NAME: new KeyValuePair("recipientEntityName", "Recipient Entity Name"),
        RECIPIENT_JURISDICTION: new KeyValuePair("recipientJurisdiction", "Recipient Jurisdiction"),
        PROVIDER_TAX_REG_CONSIDERATION: new KeyValuePair("providerTaxRegConsideration", "Provider Tax Reg Consideration"),
        RECIPIENT_TAX_REG_CONSIDERATION: new KeyValuePair("recipientTaxRegConsideration", "Recipient Tax Reg Consideration"),
        TAX_OWNER: new KeyValuePair("taxOwner", "Tax owner"),
        ACCOUNTING_OWNER: new KeyValuePair("accountingOwner", "Accounting owner"),
        TAX_PRODUCT_CATEGORY_NAME: new KeyValuePair("taxProductCategoryName", "Tax Product Category Name"),
        TAX_PRODUCT_CATEGORY_ID: new KeyValuePair("taxProductCategoryId", "Tax Product Category ID"),
        TAX_PRODUCT_NAME: new KeyValuePair("taxProductName", "Tax Product Name"),
        TAX_PRODUCT_ID: new KeyValuePair("taxProductId", "Tax Product ID"),
        EXPORT_OF_SERVICES: new KeyValuePair("exportOfServices", "Export Of Services"),
        TAX_APPROVED: new KeyValuePair("taxApproved", "Tax Approved"),
        CREATED_BY: new KeyValuePair("createdBy", "Created By"),
        LAST_UPDATED_BY: new KeyValuePair("lastUpdatedBy", "Last Updated By"),
        CREATE_DATE_TIME: new KeyValuePair("createDateTime", "Create Date Time"),
        LAST_UPDATED_DATE_TIME: new KeyValuePair("lastUpdatedDateTime", "Last Updated Date Time"),
    },
    WORKLIST_TABS: {
        NONE: "None",
        AGREEMENTS: "AGREEMENT",
        CALCULATIONS: "CALCULATION",
        ICRS: "ICRS"
    },
    MEC_TABS: {
        NONE: "None",
        PREVALIDATION: "PREVALIDATION",
        STATUS: "STATUS",
        JE_STATUS_DASHBOARD: "JE_STATUS_DASHBOARD"
    },
    MEC_PREVALIDATION_CATEGORIES: {
        NONE: "None",
        CLI_STATUS: "CLI_STATUS",
        COA_CHANGES: "COA_CHANGES",
        CLI_PTA_DATA: "CLI_PTA_DATA",
        CLI_CDT_DATA: "CLI_CDT_DATA",
        INDIRECT_TAX_CHANGES: "INDIRECT_TAX_CHANGES"
    },
    MEC_PREVALIDATION_CATEGORIES_LIST: [
        {value: "CLI_PTA_DATA", label: "CLI - PTA Data"},
        {value: "CLI_CDT_DATA", label: "CLI - CDT Data"},
        {value: "COA_CHANGES", label: "COA Changes"},
        {value: "INDIRECT_TAX_CHANGES", label: "Indirect tax changes"}
    ],
    COGNITO_ROLE_ATTRIBUTE: 'custom:Role',
    NAFN_ADMIN_ALLOWED_CDT_NAME: "ATP_CDT_NAFN_SERVICE_FEE_RATE",
    CUSTOM_PERIOD_FORMAT_STRING: "MMM/yyyy",
    PERIOD_DISPLAY_FORMAT: 'MMM-YYYY',
    PERIOD_VALUE_FORMAT: 'YYYYMM',
    DATA_SOURCE_FIELDS: {
        CURRENCY: {
            ACCESSOR: 'currency',
            DISPLAY: 'Currency'
        },
        CUSTOM_PERIOD: {
            ACCESSOR: 'customPeriod'
        },
        DATA_KEY: {
            ACCESSOR: 'dataKey'
        },
        DATA_SOURCE: {
            ACCESSOR: 'datasource',
            DISPLAY: 'Data source'
        },
        DESCRIPTION: {
            ACCESSOR: 'description',
            DISPLAY: 'Description'
        },
        FX_DATE: {
            ACCESSOR: 'fxDate',
            DISPLAY: 'FX date'
        },
        FX_RATE: {
            ACCESSOR: 'fxRate'
        },
        FX_TYPE: {
            ACCESSOR: 'fxType',
            DISPLAY: 'FX type'
        },
        PERIOD: {
            ACCESSOR: 'period',
            DISPLAY: 'Period'
        },
        VALUE: {
            ACCESSOR: 'value'
        }
    },
    DATA_SOURCE_VALUES: {
        CUSTOM_PERIOD: "Custom Period"
    },
    DUPLICATE_DATA_SOURCE_DESC_PREFIX: 'DUPLICATE - ',
    DATA_SOURCE_DATA_KEY_COUNT_SUFFIX: ' COAs selected',
    FX_TYPE_PERIOD_AVERAGE: 'Period Average',
    FX_TYPE_PERIOD_END: 'Period End',
    STAT_CURRENCY: 'STAT',
    PULL_BALANCE_STATUS: {
        STARTED: 'STARTED',
        SUCCEEDED: 'SUCCEEDED',
        FAILED: 'FAILED',
    },
    GENERATE_REVIEW_JOURNAL_STATUS: {
        STARTED: 'STARTED',
        IN_PROGRESS: 'IN PROGRESS',
        SUCCEEDED: 'SUCCEEDED',
        FAILED: 'FAILED',
    },
    PROCESSING_STATUS: {
        IN_PROGRESS: 'IN PROGRESS',
        SUCCEEDED: 'SUCCEEDED',
        FAILED: 'FAILED'
    },
    CALCULATION_STEP_FIELDS: {
        SEQUENCE: "sequence",
        NAME: "stepName",
        OVERVIEW: "expression",
        OVERVIEW_FORMATTED: "expressionFormatted",
        EXPRESSION_TOKENS: "expressionTokens",
        OUTPUT: "formulaOutput",
        TP_ENTRY_TYPE: "tpEntryType",
    },
    WORKSHEET_TOTAL_FIELDS: {
        SEQUENCE: "sequence",
        DESCRIPTION: "description",
        OVERVIEW: "expression",
        OVERVIEW_FORMATTED: "expressionFormatted",
        EXPRESSION_TOKENS: "expressionTokens",
        OUTPUT: "formulaOutput"
    },
    WORKSHEET_ALLOCATION_FORMULA_FIELDS: {
        SEQUENCE: "sequence",
        DESCRIPTION: "description",
        OVERVIEW: "expression",
        OVERVIEW_FORMATTED: "expressionFormatted",
        EXPRESSION_TOKENS: "expressionTokens",
        OUTPUT: "formulaOutput",
        MAPPING: "mapping"
    },
    BEAT_QUALIFIERS: ["INBD", "INBD-SCM"],
    BEAT_TYPES: {
        NA: "NA",
        INBD: "INBD",
        INBD2: "INBD2",
        INBD_SCM: "INBD-SCM",
    },
    BEAT_JOUNAL_TYPE_TO_PROJECT_CODE_MAPPING: {
        INBD: {
            TP_CHARGE_BASE: "BSIN",
            TP_CHARGE_MARKUP: "MUIN"
        },
        INBD2: {
            TP_CHARGE_BASE: "BSIN",
        },
        "INBD-SCM": {
            TP_CHARGE_BASE: "BSSC",
            TP_CHARGE_MARKUP: "MUSC"
        }
    },
    STAGING_VERSION_NUMBER: -1,
    COA_SEGMENT_MAPPING: {
        COMPANY: {
            UI: 'Company',
            WEB_API: 'companies',
            GL: 'company',
            VALIDATION: 'company',
        },
        GL_ACCOUNT: {
            UI: 'GL Account',
            WEB_API: 'glAccounts',
            GL: 'account',
            VALIDATION: 'account',
        },
        LOCATION: {
            UI: 'Location',
            WEB_API: 'locations',
            GL: 'location',
            VALIDATION: 'location',
        },
        COST_CENTER: {
            UI: 'Cost Center',
            WEB_API: 'costCenters',
            GL: 'costCenter',
            VALIDATION: 'costCenter',
        },
        PRODUCT_LINE: {
            UI: 'Product Line',
            WEB_API: 'productLines',
            GL: 'productLine',
            VALIDATION: 'productLine',
        },
        CHANNEL: {
            UI: 'Channel',
            WEB_API: 'channels',
            GL: 'channel',
            VALIDATION: 'channelGeo',
        },
        PROJECT: {
            UI: 'Project',
            WEB_API: 'projects',
            GL: 'project',
            VALIDATION: 'projectCode',
        }
    },
    COA_DATA_KEY_TYPE: {
        STANDARD: 'STANDARD',
        CUSTOM: 'CUSTOM'
    },
    DATA_SOURCE_PERIOD_GL_MAPPING: {
        'MTD (Month to date)': 'MTD',
        'YTD Calendar Year': 'YTD',
        'Custom Period': 'Custom Period',
        'Prior Period': 'PRIOR_MONTH',
        'YTD India': 'YTD_INDIA'
    },
    ENVIRONMENT_VARIABLES: {
        BUSINESS_LOGIC_API: '{{BUSINESS_LOGIC_API}}',
        REPORTS_API: '{{REPORTS_API}}',
        TPE_UI_STAGE: '{{TPE_UI_STAGE}}',
        AWS_REGION: '{{AWS_REGION}}',
        IDENTITY_POOL_ID: '{{IDENTITY_POOL_ID}}',
        USER_POOL_ID: '{{USER_POOL_ID}}',
        USER_POOL_APP_CLIENT_ID: '{{USER_POOL_APP_CLIENT_ID}}',
        USER_POOL_SUB_DOMAIN: '{{USER_POOL_SUB_DOMAIN}}',
        REDIRECT_URI_SIGNIN: '{{REDIRECT_URI_SIGNIN}}',
        REDIRECT_URI_SIGNOUT: '{{REDIRECT_URI_SIGNOUT}}',
        SALESFORCE_HOME: '{{SALESFORCE_HOME}}',
        APTTUS_AGREEMENT_VIEW_URL: '{{APTTUS_AGREEMENT_VIEW_URL}}',
        WEBSOCKETS_API_URL: '{{WEBSOCKETS_API}}',
        CUSTOM_TABLE_UPLOAD_BUCKET: '{{CUSTOM_TABLE_UPLOAD_BUCKET}}',
        CALCULATION_UPLOAD_BUCKET: '{{CALCULATION_UPLOAD_BUCKET}}',
        TEMPLATES_BUCKET: '{{TEMPLATES_BUCKET}}',
        CUSTOM_DATA_TABLE_TEMPLATE: '{{CUSTOM_DATA_TABLE_TEMPLATE}}',
        CUSTOM_COA_DEFINITION_TEMPLATE: '{{CUSTOM_COA_DEFINITION_TEMPLATE}}',
        CALCULATION_UPLOAD_TEMPLATE: '{{CALCULATION_UPLOAD_TEMPLATE}}',
        TP_TRENDING_PORTAL_URI: '{{TP_TRENDING_PORTAL_URI}}',
    },
    DATA_SOURCE_TYPES: {
        GENERAL_LEDGER: 'General Ledger',
        CUSTOM_DATA_TABLE: 'Custom Data Table',
        TP_ALLOCATION_WORKSHEET: 'TP Allocation Worksheet'
    },
    CUSTOM_COA_DEFINITION_PREFIX: 'ATP_COA_',
    CUSTOM_DATA_TABLE_PREFIX: 'ATP_CDT_',
    CUSTOM_DATA_TABLE_STATUSES: {
        ALL: 'All',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        ARCHIVED: 'Archived'
    },
    STATUS_ATTRIBUTE: 'status',
    ACTIVE_STATUS: 'Active',
    CLEAR_SELECTION: '-- Clear Selection --',
    TIME_TO_DISABLE_UPDATE_ALL_BALANCES: 600000, // 10 minutes
    CALCULATION_TEMPLATE_FIELDS: {
        CALCULATION_NUMBER: new KeyValuePair("calculationNumber", "Calculation Number"),
        DATA_SET_NAME: new KeyValuePair("dataSetName", "Data set name"),
        MODEL_NAME: new KeyValuePair("modelName", "TP model name"),
        TEMPLATE_NAME: new KeyValuePair("templateName", "TP calculation template name"),
    },
    TEMPLATE_FIELD_PREFIXES: {
        TEMPLATE: 'CT_',
        DATASET: 'DS_',
        TP_MODEL: 'TP Model_',
    },
    JE_FIELD_SOURCE_TYPE: {
        USER_ENTERED: 'User',
        SYSTEM_DERIVED: 'System',
        HYBRID: 'Hybrid',
    },
    JE_FIELDS: {
        COMPANY: {
            ACCESSOR: 'company',
            DISPLAY: 'Company',
            MANDATORY: true,
            SOURCE: 'System'
        },
        LOCATION: {
            ACCESSOR: 'location',
            DISPLAY: 'Location',
            MANDATORY: true,
            SOURCE: 'User',
        },
        COST_CENTER: {
            ACCESSOR: 'costCenter',
            DISPLAY: 'Cost Center',
            MANDATORY: true,
            SOURCE: 'User',
        },
        ACCOUNT: {
            ACCESSOR: 'account',
            DISPLAY: 'Account',
            MANDATORY: true,
            SOURCE: 'User',
        },
        PRODUCT_LINE: {
            ACCESSOR: 'productLine',
            DISPLAY: 'Product Line',
            MANDATORY: true,
            SOURCE: 'User',
        },
        CHANNEL_GEO: {
            ACCESSOR: 'channelGeo',
            DISPLAY: 'Channel-Geo',
            MANDATORY: true,
            SOURCE: 'User',
        },
        PROJECT_CODE: {
            ACCESSOR: 'projectCode',
            DISPLAY: 'Project Code',
            MANDATORY: true,
            SOURCE: 'User',
        },
        ENTERED_DR: {
            ACCESSOR: 'enteredDrFormatted',
            DISPLAY: 'Entered Dr',
            MANDATORY: true,
            SOURCE: 'System',
        },
        ENTERED_CR: {
            ACCESSOR: 'enteredCrFormatted',
            DISPLAY: 'Entered Cr',
            MANDATORY: true,
            SOURCE: 'System',
        },
        ACCOUNTED_DR: {
            ACCESSOR: 'accountedDrFormatted',
            DISPLAY: 'Accounted Dr',
            MANDATORY: false,
            SOURCE: 'System',
        },
        ACCOUNTED_CR: {
            ACCESSOR: 'accountedCrFormatted',
            DISPLAY: 'Accounted Cr',
            MANDATORY: false,
            SOURCE: 'System',
        },
        EXCHANGE_RATE: {
            ACCESSOR: 'exchangeRate',
            DISPLAY: 'Exchange Rate',
            MANDATORY: false,
            SOURCE: 'System',
        },
        IC_TRANSACTION_GROUPING: {
            ACCESSOR: 'icTransactionGroup',
            DISPLAY: 'IC Transaction Grouping',
            MANDATORY: true,
            SOURCE: 'System',
        },
        IC_MATCHING_ID: {
            ACCESSOR: 'icMatchingId',
            DISPLAY: 'IC Matching ID',
            MANDATORY: true,
            SOURCE: 'System',
        },
        TRADING_PARTNER: {
            ACCESSOR: 'tradingPartner',
            DISPLAY: 'Trading Partner',
            MANDATORY: true,
            SOURCE: 'System',
        },
        PROVIDER_OR_RECIPIENT: {
            ACCESSOR: 'providerOrRecipient',
            DISPLAY: 'Provider or Recipient',
            MANDATORY: true,
            SOURCE: 'System',
        },
        DOCUMENT_TYPE: {
            ACCESSOR: 'documentType',
            DISPLAY: 'Document Type',
            MANDATORY: true,
            SOURCE: 'System',
        },
        AGREEMENT_LINE_NUM: {
            ACCESSOR: 'calculationNumber',
            DISPLAY: 'Agreement Line Number',
            MANDATORY: false,
            SOURCE: 'System',
        },
        ICRS_REGISTRATION_NUM: {
            ACCESSOR: 'icrsRegistrationNumber',
            DISPLAY: 'Registration Number',
            MANDATORY: false,
            SOURCE: 'System',
        },
        PRODUCT_CAT_NAME: {
            ACCESSOR: 'productCategoryName',
            DISPLAY: 'Product Category Name',
            MANDATORY: false,
            SOURCE: 'System',
        },
        PRODUCT_SUBCAT_NAME: {
            ACCESSOR: 'productSubcategoryName',
            DISPLAY: 'Product Subcategory Name',
            MANDATORY: false,
            SOURCE: 'System',
        },
        NATURE_OF_TRANSACTION: {
            ACCESSOR: 'natureOfTransaction',
            DISPLAY: 'Nature of Transaction',
            MANDATORY: true,
            SOURCE: 'System',
        },
        SOCF_CODE: {
            ACCESSOR: 'socfCode',
            DISPLAY: 'SOCF Code',
            MANDATORY: false,
            SOURCE: 'System',
        },
        INDIRECT_TAX_RATE: {
            ACCESSOR: 'indirectTaxRate',
            DISPLAY: 'Indirect Tax Rate',
            MANDATORY: false,
            SOURCE: 'System',
        },
        ACCOUNTING_DATE: {
            ACCESSOR: 'accountingDateDisplay',
            DISPLAY: 'Accounting Date',
            MANDATORY: true,
            SOURCE: 'System',
        },
        APPROVER_COMPANY: {
            ACCESSOR: 'approverCompany',
            DISPLAY: 'Approver Company',
            MANDATORY: true,
            SOURCE: 'System',
        },
        JOURNAL_CURRENCY: {
            ACCESSOR: 'journalCurrency',
            DISPLAY: 'Journal Currency',
            MANDATORY: true,
            SOURCE: 'User',
        },
        JOURNAL_NAME: {
            ACCESSOR: 'journalName',
            DISPLAY: 'Journal Name',
            MANDATORY: true,
            SOURCE: 'System',
        },
        JOURNAL_DESC: {
            ACCESSOR: 'journalDescription',
            DISPLAY: 'Journal Description',
            MANDATORY: true,
            SOURCE: 'System',
        },
        JOURNAL_CATEGORY: {
            ACCESSOR: 'journalCategory',
            DISPLAY: 'Journal Category',
            MANDATORY: true,
            SOURCE: 'System',
        },
        TRANSACTION_TYPE: {
            ACCESSOR: 'transactionType',
            DISPLAY: 'Transaction Type',
            MANDATORY: true,
            SOURCE: 'System',
        },
        IC_JOURNAL_TYPE: {
            ACCESSOR: 'icJournalType',
            DISPLAY: 'IC Journal Type',
            MANDATORY: true,
            SOURCE: 'System',
        },
        CURRENCY_CONV_TYPE: {
            ACCESSOR: 'currencyConversionType',
            DISPLAY: 'Currency Conversion Type',
            MANDATORY: false,
            SOURCE: 'System',
        },
        CURRENCY_CONV_DATE: {
            ACCESSOR: 'currencyConversionDateDisplay',
            DISPLAY: 'Currency Conversion Date',
            MANDATORY: false,
            SOURCE: 'System',
        },
        REVERSAL_PERIOD: {
            ACCESSOR: 'reversalPeriodDisplay',
            DISPLAY: 'Reversal Period',
            MANDATORY: false,
            SOURCE: 'System',
        },
        CREDIT_MEMO_INVOICE_YR: {
            ACCESSOR: 'creditMemoInvoiceYear',
            DISPLAY: 'Credit Memo Invoice Year',
            MANDATORY: false,
            SOURCE: 'System',
        },
        TIME_REQD: {
            ACCESSOR: 'timeRequired',
            DISPLAY: 'Time Required',
            MANDATORY: false,
            SOURCE: 'System',
        },
        ENTRY_RECURRING: {
            ACCESSOR: 'entryRecurring',
            DISPLAY: 'Entry Recurring',
            MANDATORY: true,
            SOURCE: 'System',
        },
        UPSTREAM_SYSTEM: {
            ACCESSOR: 'upstreamSystem',
            DISPLAY: 'Upstream System',
            MANDATORY: false,
            SOURCE: 'System',
        },
        PROCESS_DOCUMENTED: {
            ACCESSOR: 'processDocumented',
            DISPLAY: 'Process Documented',
            MANDATORY: false,
            SOURCE: 'System',
        },
        CLOSE_MGR_TASK_NUM: {
            ACCESSOR: 'closeManagerTaskNumber',
            DISPLAY: 'Close Manager Task Number',
            MANDATORY: false,
            SOURCE: 'System',
        },
        TAX_LINK_ID: {
            ACCESSOR: 'taxLinkId',
            DISPLAY: 'Tax Link ID',
            MANDATORY: false,
            SOURCE: 'System',
        },
        TAX_EXEMPT_CODE: {
            ACCESSOR: 'taxExemptionCode',
            DISPLAY: 'Tax Exemption Code',
            MANDATORY: false,
            SOURCE: 'User',
        },
        TP_JOURNAL_SOURCE: {
            ACCESSOR: 'tpJournalSource',
            DISPLAY: 'TP Journal Source',
            MANDATORY: false,
            SOURCE: 'System',
        },
        LINE_DESC_OVERRIDE: {
            ACCESSOR: 'lineDescriptionOverride',
            DISPLAY: 'Line Description Override',
            MANDATORY: false,
            SOURCE: 'User',
        },
    },
    ADDITIONAL_REVIEW_JE_FIELDS: {
        JE_BATCH_ID: {
            ACCESSOR: 'jeBatchId',
            DISPLAY: 'JE Batch ID'
        },
        IC_EVENT_TYPE: {
            ACCESSOR: 'icEventType',
            DISPLAY: 'IC Event Type'
        },
        IC_TRANSACTION_ID: {
            ACCESSOR: 'icTransactionId',
            DISPLAY: 'Intercompany Transaction ID'
        },
        IC_LINE_OFFSET: {
            ACCESSOR: 'icLineOffset',
            DISPLAY: 'IC Line Offset'
        },
        IC_PARTNER_OFFSET: {
            ACCESSOR: 'icPartnerOffset',
            DISPLAY: 'IC Partner Offset'
        },
        PROVIDER_LEGAL_ENTITY: {
            ACCESSOR: 'providerLegalEntityName',
            DISPLAY: 'Agreement Provider Legal Entity Name'
        },
        PROVIDER_JURISDICTION: {
            ACCESSOR: 'providerJurisdiction',
            DISPLAY: 'Agreement Provider Jurisdiction'
        },
        RECEIVER_LEGAL_ENTITY: {
            ACCESSOR: 'receiverLegalEntityName',
            DISPLAY: 'Agreement Receiver Legal Entity Name'
        },
        RECEIVER_JURISDICTION: {
            ACCESSOR: 'receiverJurisdiction',
            DISPLAY: 'Agreement Receiver Jurisdiction'
        },
        GEMS_ID: {
            ACCESSOR: 'gemsId',
            DISPLAY: 'GEMS ID'
        },
        PARENT_GEMS_ID: {
            ACCESSOR: 'parentGemsId',
            DISPLAY: 'Parent GEMS ID'
        },
        TAX_ENTITY_ID: {
            ACCESSOR: 'taxEntityId',
            DISPLAY: 'Tax Entity ID'
        },
        PARENT_TAX_ENTITY_ID: {
            ACCESSOR: 'parentTaxEntityId',
            DISPLAY: 'Parent Tax Entity ID'
        },
        FTZ_SEZ_FLAG: {
            ACCESSOR: 'ftzSezFlag',
            DISPLAY: 'Ftz Sez Flag'
        }
    },
    JOURNAL_TYPES: {
        AUTO_INDIRECT_TAX: 'AUTO_INDIRECT_TAX',
        MANUAL_INDIRECT_TAX: 'MANUAL_INDIRECT_TAX',
        TP_CHARGE: 'TP_CHARGE',
        TP_CHARGE_BASE: 'TP_CHARGE_BASE',
        TP_CHARGE_MARKUP: 'TP_CHARGE_MARKUP'
    },
    JOURNAL_LINE_TYPES: {
        CONFIGURATION: 'CONFIGURATION',
        REVIEW: 'REVIEW'
    },
    CALCULATION_CONFIGURATION_STATE: {
        DATASOURCES_STEPS: 'DATASOURCES_STEPS',
        JOURNAL_CONFIGURATION: 'JOURNAL_CONFIGURATION',
        JOURNAL_REVIEW: 'JOURNAL_REVIEW'
    },
    CALCULATION_VERSIONS: {
        DRAFT_VERSION: -1,
        CURRENT_VERSION: 0
    },
    REPORT_HEADERS: {
        CALCULATIONS: 'Calculation Details Report',
        DATASOURCES: 'Data Sources Report',
        CUSTOM_COA: 'Custom COA Rollup Report',
        JOURNAL_DETAILS: 'Journal Details Report',
        TP_TRENDING: {
            'TP_TRENDING_REPORT': 'TP Trending Report'
        },
        TAX_AGREEMENT_DETAILS: 'Tax Agreement Details Report',
        TAX_AUDIT_RECORD: 'Tax Audit Record Report',
        TP_TAX: 'Margin Report (MAR) Single CLI',
        TP_TAX_VARIANCE: 'Variance Report (VAR)',
        TP_TAX_CWB_REPORT: 'Calculation Workbook (CWB)',
        TP_TAX_LEF_REPORT : 'Segmented LE Financials (LEF)',
        COA_CHANGE_MANAGEMENT : 'COA Change Management Report',
        ALLOCATION_TRENDING: 'Allocation Trending Report',
        FORMULA_SET: 'Formula Set Report',
        CALCULATION_CHANGE: 'Calculation Change Report',
        CUSTOM_DATA_AUTO_INSERT: 'Custom Data Auto Insert Report',
        PRE_VALIDATION_REPORTS: 'Pre-Validation Reports'
    },
    REPORT_TYPES: {
        CALCULATIONS: 'CALCULATIONS',
        DATASOURCES: 'DATASOURCES',
        CUSTOM_COA: 'CUSTOM_COA',
        JOURNAL_DETAILS: 'JOURNAL_DETAILS',
        TP_TRENDING: 'TP_TRENDING',
        TAX_AGREEMENT_DETAILS: 'TAX_AGREEMENT_DETAILS',
        TP_TAX: 'TP_TAX',
        TP_TAX_LEF_REPORT: 'TP_TAX_LEF',
        TP_TAX_VARIANCE: 'TP_TAX_VARIANCE',
        TP_TAX_CWB_REPORT: 'TP_TAX_CWB',
        COA_CHANGE_MANAGEMENT: 'COA_CHANGE_MANAGEMENT',
        PRE_VALIDATION_PTA: 'PRE_VALIDATION_PTA',
        PRE_VALIDATION_CDT: 'PRE_VALIDATION_CDT',
        PRE_VALIDATION_COA: 'PRE_VALIDATION_COA',
        PRE_VALIDATION_INDIRECT_TAX: 'PRE_VALIDATION_INDIRECT_TAX',
        PRE_VALIDATION_CLI_STATUS: 'PRE_VALIDATION_CLI_STATUS',
        ALLOCATION_TRENDING: 'ALLOCATION_TRENDING',
        FORMULA_SET: 'FORMULA_SET',
        CALCULATION_CHANGE: 'CALCULATION_CHANGE',
        CUSTOM_DATA_AUTO_INSERT: 'CUSTOM_DATA_AUTO_INSERT'
    },
    REPORT_STATUS: {
        SUCCESS: "success",
        RUNNING: "running",
        FAILED: "failed",
        INIT: "init",
        PENDING: "pending",
        VALIDATED: "validated",
        NOT_PRESENT: "not present",
        VALIDATION_FAILED: "validation failed"
    },
    INDIRECT_TAX_CHANGE_CLI_STATUS: {
        SUCCESS: "Success",
        IN_PROGRESS: "In Progress",
        FAILED: "Failed",
        INIT: "Init",
    },
    CUSTOM_DATA_TABLE_RECORD_FIELDS: {
        SELECT: {
            ACCESSOR: 'rowSelect',
            DISPLAY: ' ',
            WIDTH: 30,
        },
        CALCULATION_NUMBER: {
            ACCESSOR: 'calculationNumber',
            DISPLAY: 'Calculation Number',
            WIDTH: 200,
            SEARCH_ATTRIBUTE: 'calculation_number',
            MINIMUM_FILTER_VALUE_LENGTH: 3
        },
        CURRENCY: {
            ACCESSOR: 'currency',
            DISPLAY: 'Currency',
            WIDTH: 120,
            SEARCH_ATTRIBUTE: 'currency',
            MINIMUM_FILTER_VALUE_LENGTH: 2
        },
        CLASSIFICATION: {
            ACCESSOR: 'classification',
            DISPLAY: 'Classification',
            WIDTH: 240,
            SEARCH_ATTRIBUTE: 'search_classification',
            MINIMUM_FILTER_VALUE_LENGTH: 3
        },
        CADENCE: {
            ACCESSOR: 'cadence',
            DISPLAY: 'Cadence',
            WIDTH: 120,
            SEARCH_ATTRIBUTE: 'cadence',
            MINIMUM_FILTER_VALUE_LENGTH: 3
        },
        PERIOD: {
            ACCESSOR: 'period',
            DISPLAY: 'Period',
            WIDTH: 120,
            SEARCH_ATTRIBUTE: 'period',
            MINIMUM_FILTER_VALUE_LENGTH: 3
        },
        ACTIVE: {
            ACCESSOR: 'status',
            DISPLAY: 'Active (Y/N)',
            WIDTH: 100,
            SEARCH_ATTRIBUTE: 'status',
            MINIMUM_FILTER_VALUE_LENGTH: 1
        },
        UPDATED_BY: {
            ACCESSOR: 'lastUpdateUser',
            DISPLAY: 'Updated by',
            WIDTH: 120,
            MINIMUM_FILTER_VALUE_LENGTH: 3
        },
        LAST_UPDATED_DATE: {
            ACCESSOR: 'lastUpdatedDate',
            DISPLAY: 'Last updated timestamp',
            WIDTH: 100
        },
        VALUE: {
            ACCESSOR: "VALUE",
            DISPLAY: "VALUE",
            WIDTH: 150
        },
        TABLE_NAME: {
            ACCESSOR: "tableName",
            DISPLAY: "Table Name",
            SEARCH_ATTRIBUTE: 'search_table_name',
            WIDTH: 150
        },
    },
    CUSTOM_COA_ROW_FIELDS: {
        CLASSIFICATION: {
            ACCESSOR: 'classification',
            DISPLAY: 'Classification',
            SEARCH_ATTRIBUTE: 'search_classification'
        },
        SEGMENT_VALUE: {
            ACCESSOR: 'segmentValue',
            DISPLAY: 'Segment Value',
            SEARCH_ATTRIBUTE: 'segment_value'
        },
        ACTIVE: {
            ACCESSOR: 'status',
            DISPLAY: 'Active (Y/N)',
            SEARCH_ATTRIBUTE: 'status',
            MINIMUM_FILTER_VALUE_LENGTH: 1
        },
        UPDATED_BY: {
            ACCESSOR: 'lastUpdateUser',
            DISPLAY: 'Updated by',
            SEARCH_ATTRIBUTE: 'last_update_user'
        },
        LAST_UPDATED_DATE: {
            ACCESSOR: 'lastUpdatedDateDisplay',
            DISPLAY: 'Last updated date',
            SEARCH_ATTRIBUTE: 'last_updated_date'
        },
    },

    CUSTOM_COA_RECORD_FIELDS: {
        TABLE_NAME: {
            ACCESSOR: "tableName",
            DISPLAY: "Table Name",
            SEARCH_ATTRIBUTE: 'search_table_name',
            WIDTH: 150
        },
    },

    MEC_COA_CHANGES_COLUMN_HEADERS: [
        {
            ACCESSOR: 'cli',
            DISPLAY: 'CLI'
        },
        {
            ACCESSOR: 'group',
            DISPLAY: 'Group'
        },
        {
            ACCESSOR: 'stepName',
            DISPLAY: 'Step Name'
        },
        {
            ACCESSOR: 'type',
            DISPLAY: 'DataSource Type'
        },
        {
            ACCESSOR: 'description',
            DISPLAY: 'Description'
        },
        {
            ACCESSOR: 'tableName',
            DISPLAY: 'Table Name'
        },
        {
            ACCESSOR: 'classification',
            DISPLAY: 'Classification'
        },
        {
            ACCESSOR: 'segment',
            DISPLAY: 'Segment'
        },
        {
            ACCESSOR: 'coaDataKey',
            DISPLAY: 'COA Data Key'
        },
        {
            ACCESSOR: 'coaParents',
            DISPLAY: 'COA Parents'
        },
        {
            ACCESSOR: 'coaChanges',
            DISPLAY: 'COA Changes'
        },
        {
            ACCESSOR: 'workbookName',
            DISPLAY: 'Workbook Name'
        },
        {
            ACCESSOR: 'calcAssignee',
            DISPLAY: 'Calc Assignee'
        },
        {
            ACCESSOR: 'accountingOwner',
            DISPLAY: 'Accounting Owner'
        },
        {
            ACCESSOR: 'tpTaxOwner',
            DISPLAY: 'TP Tax Owner'
        }
    ],
    MEC_CLI_PTA_CDT_DATA_COLUMN_HEADERS: [
        {
            ACCESSOR: 'cli',
            DISPLAY: 'CLI'
        },
        {
            ACCESSOR: 'workbookName',
            DISPLAY: 'Workbook Name'
        },
        {
            ACCESSOR: 'workbookAssignee',
            DISPLAY: 'Workbook Assignee'
        },
        {
            ACCESSOR: 'group',
            DISPLAY: 'Group'
        },
        {
            ACCESSOR: 'cadence',
            DISPLAY: 'Cadence'
        },
        {
            ACCESSOR: 'customDataTable',
            DISPLAY: 'Custom Data Table Name'
        },
        {
            ACCESSOR: 'cdtValue',
            DISPLAY: 'Value'
        },
        {
            ACCESSOR: 'lastUpdateMonth',
            DISPLAY: 'Last Update Month'
        },
        {
            ACCESSOR: 'errorMsg',
            DISPLAY: 'Error'
        }
    ],
    MEC_INDIRECT_TAX_CHANGES_COLUMN_HEADERS: [
        {
            ACCESSOR: 'cli',
            DISPLAY: 'CLI'
        },
        {
            ACCESSOR: 'group',
            DISPLAY: 'Group'
        },
        {
            ACCESSOR: 'oldRate',
            DISPLAY: 'Old Rate'
        },
        {
            ACCESSOR: 'revisedRate',
            DISPLAY: 'Revised Rate'
        },
        {
            ACCESSOR: 'comment',
            DISPLAY: 'Comments'
        },
        {
            ACCESSOR: 'workbookName',
            DISPLAY: 'Workbook Name'
        },
        {
            ACCESSOR: 'calcAssignee',
            DISPLAY: 'Calc Assignee'
        },
        {
            ACCESSOR: 'accountingOwner',
            DISPLAY: 'Accounting Owner'
        },
        {
            ACCESSOR: 'tpTaxOwner',
            DISPLAY: 'TP Tax Owner'
        }
    ],
    MEC_STATUS_COLUMN_HEADERS: {
        SELECT: {
            ACCESSOR: 'select',
            DISPLAY: 'Select'
        },
        DAY_OR_GROUP:{
            ACCESSOR: 'dayOrGroup',
            DISPLAY: 'Day'
        },
        STATUS: {
            ACCESSOR: 'status',
            DISPLAY: 'Status'
        },
        DRY_RUN: {
            ACCESSOR: 'dryRun',
            DISPLAY: 'Dry run?'
        },
        PROGRESS: {
            ACCESSOR: 'progress',
            DISPLAY: 'Progress'
        },
        CLIS_COUNT: {
            ACCESSOR: 'numberOfCLIs',
            DISPLAY: '# of CLIs'
        },
        ESTIMATED_START: {
            ACCESSOR: 'estimatedStart',
            DISPLAY: 'Est. start'
        },
        SYSTEM_DEPENDENCY: {
            ACCESSOR: 'systemDependency',
            DISPLAY: 'System dependency'
        },
        MANUAL_CALC_DEPENDENCY: {
            ACCESSOR: 'manualCalcDependency',
            DISPLAY: 'Manual calc dependency'
        },
        RUN_BY: {
            ACCESSOR: 'runBy',
            DISPLAY: 'Run by'
        },
        OTHER_DEPENDENCIES: {
            ACCESSOR: 'otherDependencies',
            DISPLAY: 'Other dependencies'
        }
    },
    MEC_CLI_DETAILS_COLUMN_HEADERS: {
        NAME: {
            ACCESSOR: 'calculationNumber',
            DISPLAY: 'Name'
        },
        STATUS: {
            ACCESSOR: 'status',
            DISPLAY: 'Status'
        },
        DRY_RUN: {
            ACCESSOR: 'dryRun',
            DISPLAY: 'Dry run?'
        },
        EXECUTION_ERROR: {
            ACCESSOR: 'executionError',
            DISPLAY: 'Execution error'
        },
        MANUAL_CALC_DPENDENCY: {
            ACCESSOR: 'manualCalcDependency',
            DISPLAY: 'Manual calc dependency'
        },
        RUN_BY: {
            ACCESSOR: 'runBy',
            DISPLAY: 'Run by'
        },
        AVERAGE_RUN_TIME: {
            ACCESSOR: 'averageRunTime',
            DISPLAY: 'Average run time (hrs)'
        }
    },
    REVERSAL_COLUMN_HEADERS: [
        {
            ACCESSOR: 'name',
            DISPLAY: 'Name'
        },
        {
            ACCESSOR: 'type',
            DISPLAY: 'Type'
        },
        {
            ACCESSOR: 'status',
            DISPLAY: 'Status'
        },
        {
            ACCESSOR: 'runBy',
            DISPLAY: 'Run by'
        },
        {
            ACCESSOR: 'reversedBy',
            DISPLAY: 'Reversed by'
        },
        {
            ACCESSOR: 'reversedOn',
            DISPLAY: 'Start time'
        },
        {
            ACCESSOR: 'reversalEndDate',
            DISPLAY: 'End time'
        },
        {
            ACCESSOR: 'group',
            DISPLAY: 'Group'
        }
    ],
    CUSTOM_DATA_TABLE_CADENCES: ['Monthly', 'Quarterly', 'Annual'],
    CUSTOM_DATA_TABLE_ACTIVE_FLAGS: ['Y', 'N'],
    CUSTOM_DATA_TABLE_PERIOD_DISPLAY_FORMAT: 'MMM-yyyy',

    MEC_PERIOD_FORMAT: 'yyyyMM',

    TP_ENTRY_BEAT_QUALIFIER_TYPES: {
        ALL: "ALL",
        BEAT: "BEAT",
        NON_BEAT: "NON_BEAT"
    },
    WEB_SOCKET_API_ROUTES: {
        EXECUTE_CLI: "executeCLI",
        PULL_GL_BALANCE: "pullBalance",
        PULL_CDT_VALUE: "pullCDTValue",
        DRY_RUN: "dryRun",
        GENERATE_REVIEW_JOURNALS: "generateReviewJournals",
        EXECUTE_WORKSHEET: "executeWorksheet",
        EXECUTE_GROUP: "executeGroup",
        EXECUTE_BULK_REVERSAL: "executeBulkReversal",
        PERFORM_BULK_ACTION: "performBulkAction",
    },
    TIMEOUT_MAX_RETRIED_ERROR: "TIMEOUT_MAX_RETRIED_ERROR",
    CALCULATION_STATUS: {
        ACTIVE: "Active",
        APPROVED: "Approved",
        DRAFT_IN_PROGRESS: "Draft in Progress",
        INACTIVE: "Inactive",
        PENDING_AGREEMENT_ACTIVATION: "Pending Agreement Activation",
        PENDING_REVIEW: "Pending Review",
        PENDING_ACTIVATION: "Pending Activation",
        TERMINATED: "Terminated",
        YET_TO_START: "Yet to Start"
    },
    TP_TAX_REPORT_CATEGORY: {
        ALLOCATION: "Allocation",
        MARGIN: 'Margin'
    },
    ATP_CUSTOM_REACT_TABLE_CSS: " atp-custom-react-table",
    VIEW_MODE: {
        EDITABLE: "EDITABLE",
        READ_ONLY: "READ_ONLY",
        FROZEN: "FROZEN",
        TEMPLATE_READ_ONLY: "TEMPLATE_READ_ONLY"
    },
    TP_ALLOCATION_BUILDER_STATE: {
        UNKNOWN: "UNKNOWN",
        PLACEHOLDERS: "PLACEHOLDERS",
        DATASOURCES: "DATASOURCES",
        TOTALS: "TOTALS",
        ALLOCATION_GROUPS: "ALLOCATION_GROUPS"
    },
    TP_ALLOCATION_MAPPING_TYPE: {
        FORMULA: 'FORMULA',
        RECIPIENT: 'RECIPIENT'
    },
    NEW_COA_PLACEHOLDER_SEGMENT: "NEW_COA_PLACEHOLDER_SEGMENT",
    WORKSHEET_ENTITY_TYPES: {
        ALLOCATION_FORMULA: "ALLOCATION_FORMULA",
        ALLOCATION_GROUP: "ALLOCATION_GROUP",
        DATASOURCE: "DATASOURCE",
        PLACEHOLDER: "PLACEHOLDER",
        TOTAL: "TOTAL"
    },
    EXECUTION_STATUS: {
        NOT_STARTED: 'Not Started',
        PENDING: 'Pending',
        PROCESSING: 'Processing',
        PROCESSING_PARTIALLY_FAILED: 'Processing (Partially Failed)',
        PARTIALLY_FAILED: 'Partially Failed',
        FAILED: 'Failed',
        FAILED_TO_START: 'Failed to Start',
        FAILED_TO_RUN_ALLOCATION_WORKSHEETS: 'Failed to run allocation worksheets',
        CALCULATION_STARTED: 'Calculation Started',
        CALCULATION_SUCCEEDED: 'Succeeded',
        CALCULATION_FAILED: 'Calculation Failed',
        POSTING_STARTED: 'Posting Started',
        POSTING_IN_PROGRESS_SUBMITTED_TO_IPE: 'Posting in Progress - Submitted to IPE',
        POSTING_IN_PROGRESS_SUBMITTED_TO_SPURSH: 'Posting in Progress - Submitted to Spursh',
        POSTING_FAILED: 'Posting Failed',
        POSTING_SKIPPED: 'Posting Skipped',
        GL_POSTED: 'GL Posted',
        GL_CREATED: 'GL Created',
        GL_FAILED: 'GL Failed',
        GL_PROCESSING: 'GL Processing',
        GL_CREATION_ERROR: 'GL Creation Error',
        REVERSAL_CREATED: 'Reversal Created',
        REVERSAL_PENDING: 'Reversal Pending',
        REVERSAL_STARTED: 'Reversal Started',
        REVERSAL_SUCCEEDED: 'Reversal Succeeded',
        REVERSAL_SUCCEEDED_POSTING_SKIPPED: 'Reversal Succeeded (Posting Skipped)',
        REVERSAL_FAILED: 'Reversal Failed',
        REVERSAL_BLOCKED: 'GL Posted (Reversal Blocked)',
        PARTIALLY_REVERSED: 'Partially Reversed',
        POSTING_NOT_STARTED: 'Posting Not Started',
        DRY_RUN_SUCCEEDED: 'Dry Run Succeeded',
        DRY_RUN_FAILED: 'Dry Run Failed'
    },
    REVERSAL_SEARCH_OPTIONS: {
        WORKBOOK: 'Workbook',
        CLI: 'CLI'
    },
    REVERSAL_ITEM_TYPES: {
        WORKBOOK: 'Workbook',
        CLI: 'CLI',
        GROUP: 'Group'
    }
});

export const PENDING_EXECUTION_STATUSES = Object.freeze([
    CONSTANTS.EXECUTION_STATUS.NOT_STARTED,
    CONSTANTS.EXECUTION_STATUS.PENDING,
    CONSTANTS.EXECUTION_STATUS.REVERSAL_PENDING
]);

export const IN_PROGRESS_EXECUTION_STATUSES = Object.freeze([
    CONSTANTS.EXECUTION_STATUS.PROCESSING,
    CONSTANTS.EXECUTION_STATUS.PROCESSING_PARTIALLY_FAILED,
    CONSTANTS.EXECUTION_STATUS.CALCULATION_STARTED,
    CONSTANTS.EXECUTION_STATUS.POSTING_STARTED,
    CONSTANTS.EXECUTION_STATUS.POSTING_IN_PROGRESS_SUBMITTED_TO_IPE,
    CONSTANTS.EXECUTION_STATUS.POSTING_IN_PROGRESS_SUBMITTED_TO_SPURSH,
    CONSTANTS.EXECUTION_STATUS.REVERSAL_STARTED,
    CONSTANTS.EXECUTION_STATUS.GL_CREATED,
    CONSTANTS.EXECUTION_STATUS.REVERSAL_CREATED
]);

export const SUCCESSFUL_EXECUTION_STATUSES = Object.freeze([
    CONSTANTS.EXECUTION_STATUS.CALCULATION_SUCCEEDED,
    CONSTANTS.EXECUTION_STATUS.POSTING_SKIPPED,
    CONSTANTS.EXECUTION_STATUS.GL_POSTED,
    CONSTANTS.EXECUTION_STATUS.REVERSAL_SUCCEEDED,
    CONSTANTS.EXECUTION_STATUS.REVERSAL_BLOCKED,
    CONSTANTS.EXECUTION_STATUS.PARTIALLY_REVERSED,
    CONSTANTS.EXECUTION_STATUS.REVERSAL_SUCCEEDED_POSTING_SKIPPED,
    CONSTANTS.EXECUTION_STATUS.DRY_RUN_SUCCEEDED
]);

export const FAILED_EXECUTION_STATUSES = Object.freeze([
    CONSTANTS.EXECUTION_STATUS.PARTIALLY_FAILED,
    CONSTANTS.EXECUTION_STATUS.FAILED,
    CONSTANTS.EXECUTION_STATUS.FAILED_TO_START,
    CONSTANTS.EXECUTION_STATUS.FAILED_TO_RUN_ALLOCATION_WORKSHEETS,
    CONSTANTS.EXECUTION_STATUS.CALCULATION_FAILED,
    CONSTANTS.EXECUTION_STATUS.POSTING_FAILED,
    CONSTANTS.EXECUTION_STATUS.GL_FAILED,
    CONSTANTS.EXECUTION_STATUS.REVERSAL_FAILED,
    CONSTANTS.EXECUTION_STATUS.GL_CREATION_ERROR,
    CONSTANTS.EXECUTION_STATUS.DRY_RUN_FAILED
]);

export const REVERSAL_ALLOWED_EXECUTION_STATUSES = Object.freeze([
    CONSTANTS.EXECUTION_STATUS.GL_POSTED,
    CONSTANTS.EXECUTION_STATUS.POSTING_SKIPPED,
    CONSTANTS.EXECUTION_STATUS.REVERSAL_FAILED,
    CONSTANTS.EXECUTION_STATUS.PARTIALLY_FAILED,
    CONSTANTS.EXECUTION_STATUS.PARTIALLY_REVERSED
]);

export const TAX_AUDIT_RECORDS_PAGE_LIMIT = 20;
export const INDIRECT_TAX_CHANGE_RECORDS_PAGE_LIMIT = 20;

export const REPORT_STATUS_TO_ICON_TYPE: {
    [key: string]: StatusIndicatorProps.Type;
} = Object.freeze({
    [CONSTANTS.REPORT_STATUS.INIT]: "loading",
    [CONSTANTS.REPORT_STATUS.RUNNING]: "loading",
    [CONSTANTS.REPORT_STATUS.VALIDATED]: "loading",
    [CONSTANTS.REPORT_STATUS.PENDING]: "loading",
    [CONSTANTS.REPORT_STATUS.FAILED]: "error",
    [CONSTANTS.REPORT_STATUS.VALIDATION_FAILED]: "error",
    [CONSTANTS.REPORT_STATUS.NOT_PRESENT]: "error",
    [CONSTANTS.REPORT_STATUS.SUCCESS]: "success"
});

export const INDIRECT_TAX_CHANGE_CLI_STATUS_TO_ICON_TYPE: {
    [key: string]: StatusIndicatorProps.Type;
} = Object.freeze({
    [CONSTANTS.INDIRECT_TAX_CHANGE_CLI_STATUS.INIT]: "loading",
    [CONSTANTS.INDIRECT_TAX_CHANGE_CLI_STATUS.IN_PROGRESS]: "loading",
    [CONSTANTS.INDIRECT_TAX_CHANGE_CLI_STATUS.SUCCESS]: "success",
    [CONSTANTS.INDIRECT_TAX_CHANGE_CLI_STATUS.FAILED]: "error",
});

export const CLI_JE_STATUS_TO_ICON_TYPE: {
    [key: string]: StatusIndicatorProps.Type;
} =  Object.freeze({
    [CONSTANTS.EXECUTION_STATUS.GL_FAILED]: "error",
    [CONSTANTS.EXECUTION_STATUS.GL_POSTED]: "success",
    [CONSTANTS.EXECUTION_STATUS.GL_PROCESSING]: "in-progress",
    [CONSTANTS.EXECUTION_STATUS.REVERSAL_BLOCKED]: "success",
    [CONSTANTS.EXECUTION_STATUS.POSTING_FAILED]: "error",
    [CONSTANTS.EXECUTION_STATUS.POSTING_STARTED]: "in-progress",
    [CONSTANTS.EXECUTION_STATUS.POSTING_SKIPPED]: "info",
    [CONSTANTS.EXECUTION_STATUS.REVERSAL_FAILED]: "error",
    [CONSTANTS.EXECUTION_STATUS.REVERSAL_PENDING]: "pending",
    [CONSTANTS.EXECUTION_STATUS.REVERSAL_STARTED]: "in-progress",
    [CONSTANTS.EXECUTION_STATUS.REVERSAL_SUCCEEDED]: "success",
    [CONSTANTS.EXECUTION_STATUS.POSTING_NOT_STARTED]: "info"
});

export const CUSTOM_DATA_TABLE_CONSTANTS = Object.freeze({
    CONFIG_VIEW_FIELDS: [
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.SELECT,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CALCULATION_NUMBER,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CURRENCY,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CLASSIFICATION,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CADENCE,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.PERIOD,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.VALUE,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.ACTIVE,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.UPDATED_BY,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.LAST_UPDATED_DATE,
    ],
    DUPLICATE_ROWS_VIEW_FIELDS: [
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CALCULATION_NUMBER,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.PERIOD,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.LAST_UPDATED_DATE,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CURRENCY,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CLASSIFICATION,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CADENCE,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.VALUE,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.ACTIVE
    ],
    CDT_VALUES_SELECTOR_FIELDS: [
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CALCULATION_NUMBER,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CURRENCY,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CLASSIFICATION,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CADENCE,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.PERIOD,
        CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.VALUE,
    ],
    VALUE: "VALUE",
});
export const TP_TAX_REPORT_FORMATTING_EXCLUSION_SET = Object.freeze(new Set<string>(['Agreement Number',
    'Berry Ratio [BR]']));

export default CONSTANTS;